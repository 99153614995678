import React from 'react';
import Misson from '../components/sections/about/Mission';
import CultureContainer from '../components/sections/about/CultureContainer';

const About = () => {
  return (
    <>
      <Misson />
      <CultureContainer />
    </>
  );
}
export default About;
